const getDefaultState = () => {
  return {
    nomeTesseratiGold: null,
    cognomeTesseratiGold: null,
    dataTesseratiGold: new Date(),
    tesseraAttivaTesseratiGold: 1,
    rowsToSkipTesseratiGold: 0,
    fetchRowsTesseratiGold: 25,
    currentPageTesseratiGold: 1,
    sortColumnTesseratiGold: "cognome",
    sortOrderTesseratiGold: "asc",
  };
};
const state = getDefaultState();

const getters = {
  nomeTesseratiGold: (state) => state.nomeTesseratiGold,
  cognomeTesseratiGold: (state) => state.cognomeTesseratiGold,
  dataTesseratiGold: (state) => state.dataTesseratiGold,
  tesseraAttivaTesseratiGold: (state) => state.tesseraAttivaTesseratiGold,
  rowsToSkipTesseratiGold: (state) => state.rowsToSkipTesseratiGold,
  fetchRowsTesseratiGold: (state) => state.fetchRowsTesseratiGold,
  currentPageTesseratiGold: (state) => state.currentPageTesseratiGold,
  sortColumnTesseratiGold: (state) => state.sortColumnTesseratiGold,
  sortOrderTesseratiGold: (state) => state.sortOrderTesseratiGold,
};

// const actions = {};
const mutations = {
  setNomeTesseratiGold: (state, value) => {
    state.nomeTesseratiGold = value;
  },
  setCognomeTesseratiGold: (state, value) => {
    state.cognomeTesseratiGold = value;
  },
  setDataTesseratiGold: (state, value) => {
    state.dataTesseratiGold = value;
  },
  setTesseraAttiva: (state, value) => {
    state.tesseraAttivaTesseratiGold = value;
  },
  setFetchRowsTesseratiGold: (state, value) => {
    state.currentPageTesseratiGold = 1;
    state.rowsToSkipTesseratiGold = 0;
    state.fetchRowsTesseratiGold = value;
  },
  setCurrentPageTesseratiGold: (state, value) => {
    state.currentPageTesseratiGold = value;
    state.rowsToSkipTesseratiGold = parseInt(
      (state.currentPageTesseratiGold - 1) * state.fetchRowsTesseratiGold
    );
  },
  setSortColumnTesseratiGold: (state, value) => {
    state.sortColumnTesseratiGold = value;
  },
  setSortOrderTesseratiGold: (state, value) => {
    state.sortOrderTesseratiGold = value;
  },
  resetFiltersTesseratiGold: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipTesseratiGold: (state) => {
    state.currentPageTesseratiGold = 1;
    state.rowsToSkipTesseratiGold = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
