<template>
  <!-- <router-view v-if="menuLoaded" ref="pageElements" /> -->
  <router-view ref="pageElements" />
</template>

<script>
import {
  defineComponent,
  nextTick,
  onMounted,
  computed,
  onBeforeMount,
  ref,
  onUpdated,
} from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { useRoute } from "vue-router";
import { useIsAuthenticated } from "@/composition-api/useIsAuthenticated";
import { alertFailed, alertSuccess } from "./composables/swAlert";

export default defineComponent({
  name: "app",
  setup() {
    const route = useRoute();
    const paymentError = route.query.err;
    const store = useStore();
    const isAuthenticated = useIsAuthenticated();
    const menuLoaded = computed(() => store.getters.isMenuLoaded);
    const pageElements = ref(null);

    if (paymentError === "1") {
      alertFailed("Il pagamento non è andato a buon fine");
    } else if (paymentError === "0") {
      alertSuccess("Pagamento eseguito con successo");
    }
    onMounted(async () => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      // if (menuLoaded.value) store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      nextTick(() => {
        initializeComponents();
      });
      document.addEventListener("show.bs.dropdown", function () {
        const trs = document.querySelectorAll("tr:not(.modal tr)");
        const dropdownButtons = document.querySelectorAll(
          ".bi-three-dots-vertical"
        );
        const dropdownMenus = document.querySelectorAll(".dropdown-menu");
        if (trs.length < 9) {
          trs.forEach((element, index) => {
            dropdownMenus.forEach((dropdown, indexdd) => {
              dropdownButtons.forEach((dots, indexdots) => {
                if (dropdown.closest(".modal")) {
                  return; // Salta l'elaborazione per i dropdown nei modali
                }
                const position = () => {
                  dropdown.classList.add("position-fixed");
                  const elementPosition = element.getBoundingClientRect();
                  var style = document.createElement("style");
                  style.type = "text/css";
                  style.innerHTML = `.dropdown-transform${index} { transform: translate3d(-56px, 45px, 0px) !important; inset: ${elementPosition.y}px 0px auto auto!important;  left:${elementPosition.x}px; top: ${elementPosition.y}px`;

                  document.getElementsByTagName("head")[0].appendChild(style);

                  dropdown.classList.add(`dropdown-transform${index}`);
                };
                if (indexdd === index - 1 && indexdots === index - 1) {
                  document.addEventListener("scroll", position, true);
                  position();
                }
              });
            });
          });
        }
      });
    });

    const isProd = process.env.VUE_APP_IS_PROD;
    const script = document.createElement("script");
    script.src =
      isProd === "1"
        ? `https://ecommerce.nexi.it/ecomm/XPayBuild/js?alias=${process.env.VUE_APP_ALIAS_MERCHANT_PROD}`
        : `https://int-ecommerce.nexi.it/ecomm/XPayBuild/js?alias=${process.env.VUE_APP_ALIAS_MERCHANT_TEST}`;
    script.async = true;

    const style = document.createElement("style");
    style.innerHTML =
      isProd === "1"
        ? ""
        : `
        .aside .aside-logo,
        .aside .aside-menu,
        .menu-column {
          background: brown;
        }
        .username,
        .username:hover {
          background: #a5292aab !important;
        }
        .aside-enabled.aside-fixed.toolbar-fixed .toolbar {
          background: #a5292aab !important;
        }
        .multiselect-tag {
          background: #a5292aab !important;
        }`;

    document.head.appendChild(script);
    document.head.appendChild(style);

    return {
      menuLoaded,
      pageElements,
    };
  },
});
</script>

<style lang="scss">
@import "bootstrap-icons/font/bootstrap-icons.css";
@import "quill/dist/quill.snow.css";
@import "animate.css";
@import "sweetalert2/dist/sweetalert2.css";
@import "nouislider/distribute/nouislider.css";
@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "socicon/css/socicon.css";
@import "line-awesome/dist/line-awesome/css/line-awesome.css";
@import "dropzone/dist/dropzone.css";
@import "@vueform/multiselect/themes/default.css";
@import "prism-themes/themes/prism-shades-of-purple.css";
@import "element-plus/dist/index.css";

// Main demo style scss
@import "assets/sass/plugins";
@import "assets/sass/style";

// Dark mode demo style scss
//@import "assets/sass/plugins.dark";
//@import "assets/sass/style.dark";

//RTL version styles
//@import "assets/css/style.rtl.css";
</style>
