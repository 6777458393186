const getDefaultState = () => {
  return {
    tipologiaConfigurazioniRaduni: null,
    disciplineConfigurazioniRaduni: "",
    stagioneSportivaConfigurazioniRaduni: new Date().getFullYear(),

    rowsToSkipConfigurazioniRaduni: 0,
    fetchRowsConfigurazioniRaduni: 25,
    currentPageConfigurazioniRaduni: 1,
    sortColumnConfigurazioniRaduni: "id",
    sortOrderConfigurazioniRaduni: "desc",

    selected_discipline_conf_raduni_small: [],
  };
};
const state = getDefaultState();

const getters = {
  disciplineConfigurazioniRaduni: (state) =>
    state.disciplineConfigurazioniRaduni,
  tipologiaConfigurazioniRaduni: (state) => state.tipologiaConfigurazioniRaduni,
  stagioneSportivaConfigurazioniRaduni: (state) =>
    state.stagioneSportivaConfigurazioniRaduni,

  rowsToSkipConfigurazioniRaduni: (state) =>
    state.rowsToSkipConfigurazioniRaduni,
  fetchRowsConfigurazioniRaduni: (state) => state.fetchRowsConfigurazioniRaduni,
  currentPageConfigurazioniRaduni: (state) =>
    state.currentPageConfigurazioniRaduni,
  sortColumnConfigurazioniRaduni: (state) =>
    state.sortColumnConfigurazioniRaduni,
  sortOrderConfigurazioniRaduni: (state) => state.sortOrderConfigurazioniRaduni,

  selected_discipline_conf_raduni_small: (state) =>
    state.selected_discipline_conf_raduni_small,
};

// const actions = {};
const mutations = {
  setDisciplineConfigurazioniRaduni: (state, value) => {
    state.disciplineConfigurazioniRaduni = value;
  },
  setTipologiaConfigurazioniRaduni: (state, value) => {
    state.tipologiaConfigurazioniRaduni = value;
  },
  setStagioneSportivaConfigurazioniRaduni: (state, value) => {
    state.stagioneSportivaConfigurazioniRaduni = value;
  },

  setFetchRowsConfigurazioniRaduni: (state, value) => {
    state.currentPageConfigurazioniRaduni = 1;
    state.rowsToSkipConfigurazioniRaduni = 0;
    state.fetchRowsConfigurazioniRaduni = value;
  },
  setCurrentPageConfigurazioniRaduni: (state, value) => {
    state.currentPageConfigurazioniRaduni = value;
    state.rowsToSkipConfigurazioniRaduni = parseInt(
      (state.currentPageConfigurazioniRaduni - 1) *
        state.fetchRowsConfigurazioniRaduni
    );
  },
  setSortColumnConfigurazioniRaduni: (state, value) => {
    state.sortColumnConfigurazioniRaduni = value;
  },
  setSortOrderConfigurazioniRaduni: (state, value) => {
    state.sortOrderConfigurazioniRaduni = value;
  },
  resetFiltersConfigurazioniRaduni: (state) => {
    Object.assign(state, getDefaultState());
  },

  setRowsToSkipConfigurazioniRaduni: (state) => {
    state.currentPageConfigurazioniRaduni = 1;
    state.rowsToSkipConfigurazioniRaduni = 0;
  },

  setselected_discipline_conf_raduni_small: (state, value) => {
    state.selected_discipline_conf_raduni_small = value;
  },
};

export default {
  state,
  getters,
  mutations,
};
