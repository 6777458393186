const getDefaultState = () => {
  return {
    tipologiaConfigurazioniRaduniSmall: null,
    disciplineConfigurazioniRaduniSmall: "",
    stagioneSportivaConfigurazioniRaduniSmall: new Date().getFullYear(),

    rowsToSkipConfigurazioniRaduniSmall: 0,
    fetchRowsConfigurazioniRaduniSmall: 5,
    currentPageConfigurazioniRaduniSmall: 1,
    sortColumnConfigurazioniRaduniSmall: "id",
    sortOrderConfigurazioniRaduniSmall: "desc",

    selected_discipline_conf_raduni: [],
  };
};
const state = getDefaultState();

const getters = {
  disciplineConfigurazioniRaduniSmall: (state) =>
    state.disciplineConfigurazioniRaduniSmall,
  tipologiaConfigurazioniRaduniSmall: (state) =>
    state.tipologiaConfigurazioniRaduniSmall,
  stagioneSportivaConfigurazioniRaduniSmall: (state) =>
    state.stagioneSportivaConfigurazioniRaduniSmall,

  rowsToSkipConfigurazioniRaduniSmall: (state) =>
    state.rowsToSkipConfigurazioniRaduniSmall,
  fetchRowsConfigurazioniRaduniSmall: (state) =>
    state.fetchRowsConfigurazioniRaduniSmall,
  currentPageConfigurazioniRaduniSmall: (state) =>
    state.currentPageConfigurazioniRaduniSmall,
  sortColumnConfigurazioniRaduniSmall: (state) =>
    state.sortColumnConfigurazioniRaduniSmall,
  sortOrderConfigurazioniRaduniSmall: (state) =>
    state.sortOrderConfigurazioniRaduniSmall,

  selected_discipline_conf_raduni: (state) =>
    state.selected_discipline_conf_raduni,
};

// const actions = {};
const mutations = {
  setDisciplineConfigurazioniRaduniSmall: (state, value) => {
    state.disciplineConfigurazioniRaduniSmall = value;
  },
  setTipologiaConfigurazioniRaduniSmall: (state, value) => {
    state.tipologiaConfigurazioniRaduniSmall = value;
  },
  setStagioneSportivaConfigurazioniRaduniSmall: (state, value) => {
    state.stagioneSportivaConfigurazioniRaduniSmall = value;
  },

  setFetchRowsConfigurazioniRaduniSmall: (state, value) => {
    state.currentPageConfigurazioniRaduniSmall = 1;
    state.rowsToSkipConfigurazioniRaduniSmall = 0;
    state.fetchRowsConfigurazioniRaduniSmall = value;
  },
  setCurrentPageConfigurazioniRaduniSmall: (state, value) => {
    state.currentPageConfigurazioniRaduniSmall = value;
    state.rowsToSkipConfigurazioniRaduniSmall = parseInt(
      (state.currentPageConfigurazioniRaduniSmall - 1) *
        state.fetchRowsConfigurazioniRaduniSmall
    );
  },
  setSortColumnConfigurazioniRaduniSmall: (state, value) => {
    state.sortColumnConfigurazioniRaduniSmall = value;
  },
  setSortOrderConfigurazioniRaduniSmall: (state, value) => {
    state.sortOrderConfigurazioniRaduniSmall = value;
  },
  resetFiltersConfigurazioniRaduniSmall: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipConfigurazioniRaduniSmall: (state) => {
    state.currentPageConfigurazioniRaduniSmall = 1;
    state.rowsToSkipConfigurazioniRaduniSmall = 0;
  },

  setselected_discipline_conf_raduni: (state, value) => {
    state.selected_discipline_conf_raduni = value;
  },
};

export default {
  state,
  getters,
  mutations,
};
