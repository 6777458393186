import services from "../../axios/dbManag";
import { trimInput } from "@/composables/trimInput";

const getEntiAggregatiNomeCodAffApi = (denominazione) => {
  return services.dbManag
    .post("/anagrafiche/enti-aggregati/list", {
      denominazione,
      fetchRows: 15,
      rowsToSkip: 0,
      sortColumn: "id",
      sortOrder: "DESC",
    })
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      state.errorPersone = true;
    });
};

const getSocietaNomeCodAffApi = (denominazione) => {
  return services.dbManag
    .post("/anagrafiche/societa/list", {
      denominazione,
      fetchRows: 15,
      rowsToSkip: 0,
      sortColumn: "id",
      sortOrder: "DESC",
    })
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      state.errorPersone = true;
    });
};

const getSocietaAllenamentoApi = (denominazione) => {
  return services.dbManag
    .post("/anagrafiche/societa-raduni/list", {
      denominazione,
      fetchRows: 15,
      rowsToSkip: 0,
      sortColumn: "id",
      sortOrder: "DESC",
    })
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      state.errorPersone = true;
    });
};

const getSocietaProvApi = (denominazione) => {
  return services.dbManag
    .post("/lookup/societa/trasferienti/list", {
      denominazione,
      fetchRows: 15,
      rowsToSkip: 0,
      sortColumn: "id",
      sortOrder: "DESC",
    })
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      state.errorPersone = true;
    });
};

const getSocietaListApi = (
  cod_affiliazione,
  denominazione,
  comitato,
  rowsToSkip,
  fetchRows,
  sortColumn,
  sortOrder,
  stagione,
  stagioneCorrenteSocieta,
  disciplina,
  affiliazioneDal,
  affiliazioneAl,
  regione,
  provincia,
  comune,
  documentiModificabili,
  stato
) => {
  return services.dbManag
    .post("/anagrafiche/societa/list", {
      cod_affiliazione: trimInput(cod_affiliazione),
      denominazione: trimInput(denominazione),
      comitato,
      rowsToSkip,
      fetchRows,
      sortColumn,
      sortOrder,
      stagione,
      stagioneCorrenteSocieta,
      disciplina,
      affiliazioneDal,
      affiliazioneAl,
      regione,
      provincia,
      comune,
      documentiModificabili,
      stato,
    })
    .then((res) => {
      return res.data;
    });
};

const getDefaultState = () => {
  return {
    societa: [],
    totalElements: 0,
    currentPageSocieta: 1,
    loadingSocieta: true,
    errorSocieta: false,
    campiLockedSocieta: "",
    comandiLockedSocieta: "",

    cod_affiliazione: null,
    denominazioneSocieta: null,
    comitato: null,
    rowsToSkipSocieta: 0,
    fetchRowsSocieta: 25,
    sortColumnSocieta: "id",
    sortOrderSocieta: "desc",
    stagioneSocieta: null,
    stagioneCorrenteSocieta: null,
    tutteStagioniSocieta: false,
    disciplina: null,
    affiliazioneDal: null,
    affiliazioneAl: null,
    regione: null,
    provincia: null,
    comune: null,
    comuneText: "",
    documentiModificabili: null,
    stato: null,
    societaNomeCodAff: [],
    societaAllenamento: [],
  };
};
const state = getDefaultState();

const getters = {
  societa: (state) => state.societa,
  loadingSocieta: (state) => state.loadingSocieta,
  errorSocieta: (state) => state.errorSocieta,
  currentPageSocieta: (state) => state.currentPageSocieta,

  tutteStagioniSocieta: (state) => state.tutteStagioniSocieta,

  totalElements: (state) => state.totalElements,
  campiLockedSocieta: (state) => state.campiLockedSocieta,
  comandiLockedSocieta: (state) => state.comandiLockedSocieta,

  cod_affiliazione: (state) => state.cod_affiliazione,
  denominazioneSocieta: (state) => state.denominazioneSocieta,
  comitato: (state) => state.comitato,
  rowsToSkipSocieta: (state) => state.rowsToSkipSocieta,
  fetchRowsSocieta: (state) => state.fetchRowsSocieta,
  sortColumnSocieta: (state) => state.sortColumnSocieta,
  sortOrderSocieta: (state) => state.sortOrderSocieta,
  stagioneSocieta: (state) => state.stagioneSocieta,
  stagioneCorrenteSocieta: (state) => state.stagioneCorrenteSocieta,
  disciplina: (state) => state.disciplina,
  affiliazioneDal: (state) => state.affiliazioneDal,
  affiliazioneAl: (state) => state.affiliazioneAl,
  regione: (state) => state.regione,
  provincia: (state) => state.provincia,
  comune: (state) => state.comune,
  documentiModificabili: (state) => state.documentiModificabili,
  stato: (state) => state.stato,

  societaNomeCodAff: (state) => state.societaNomeCodAff,
  societaAllenamento: (state) => state.societaAllenamento,
  comuneText: (state) => state.comuneText,
};
const actions = {
  getSocietaList: async (stateObj) => {
    const { state } = stateObj;
    if (state.stagioneSocieta === null)
      state.stagioneSocieta = stateObj.rootState.LookupStagione.defaultStagione;
    state.errorSocieta = false;
    if (!state.loadingSocieta) stateObj.commit("setloading");
    const res = await getSocietaListApi(
      state.cod_affiliazione,
      state.denominazioneSocieta,
      state.comitato,
      state.rowsToSkipSocieta,
      state.fetchRowsSocieta,
      state.sortColumnSocieta,
      state.sortOrderSocieta,
      state.tutteStagioniSocieta ? 10000 : state.stagioneSocieta,
      state.stagioneCorrenteSocieta,
      state.disciplina,
      state.affiliazioneDal,
      state.affiliazioneAl,
      state.regione,
      state.provincia,
      state.comune,
      state.documentiModificabili,
      state.stato
    );
    stateObj.commit("setSocietaList", res);
  },

  getSocietaNomeCodAff: async (stateObj, string) => {
    const denominazione = string;
    // const cod_affiliazione = string.split(" ")[1];
    const res = await getSocietaNomeCodAffApi(denominazione);
    stateObj.commit("setSocietaNomeCodAffList", res);
  },
  getSocietaAllenamento: async (stateObj, string) => {
    const denominazione = string;
    // const cod_affiliazione = string.split(" ")[1];
    const res = await getSocietaAllenamentoApi(denominazione);
    stateObj.commit("setSocietaAllenamentoList", res);
  },

  getEntiNomeCodAff: async (stateObj, string) => {
    const denominazione = string;
    // const cod_affiliazione = string.split(" ")[1];

    const res = await getEntiAggregatiNomeCodAffApi(denominazione);

    stateObj.commit("setSocietaNomeCodAffList", res);
  },

  getSocietaProv: async (stateObj, string) => {
    const denominazione = string;
    // const cod_affiliazione = string.split(" ")[1];

    const res = await getSocietaProvApi(denominazione);
    stateObj.commit("setSocietaProvList", res);
  },
};

const mutations = {
  emptySocietaNomeCodAff: (state) => {
    state.societaNomeCodAff.splice(0, state.societaNomeCodAff.length);
  },
  setSocietaNomeCodAffList: (state, res) => {
    const { results } = res;
    state.societaNomeCodAff.splice(0, state.societaNomeCodAff.length);
    results.forEach((element) => {
      state.societaNomeCodAff.push(element);
    });
  },

  emptySocietaAllenamento: (state) => {
    state.societaAllenamento.splice(0, state.societaAllenamento.length);
  },
  setSocietaAllenamentoList: (state, res) => {
    const { results } = res;
    state.societaAllenamento.splice(0, state.societaAllenamento.length);
    results.forEach((element) => {
      state.societaAllenamento.push(element);
    });
  },

  setSocietaProvList: (state, res) => {
    const { results } = res;
    state.societaNomeCodAff.splice(0, state.societaNomeCodAff.length);
    results.forEach((element) => {
      state.societaNomeCodAff.push(element);
    });
  },
  setStagioneSocieta: (state, value) => {
    state.stagioneSocieta = value;
  },
  setSocietaList: (
    state,
    { campi_Locked, comandi_Locked, record, results }
  ) => {
    state.societa.splice(0, state.societa.length);
    state.campiLockedSocieta = campi_Locked;
    state.comandiLockedSocieta = comandi_Locked;
    state.totalElements = record;
    results.forEach((soc) => {
      state.societa.push(soc);
    });
    if (state.loadingSocieta) state.loadingSocieta = !state.loadingSocieta;
  },
  seterror: (state) => {
    state.errorSocieta = !state.errorSocieta;
  },
  setloading: (state) => {
    state.loadingSocieta = !state.loadingSocieta;
  },
  setFetchRowsSocieta: (state, value) => {
    state.fetchRowsSocieta = value;
  },
  setPageSocieta: (state, value) => {
    state.currentPageSocieta = value;
    state.rowsToSkipSocieta = parseInt(
      (state.currentPageSocieta - 1) * state.fetchRowsSocieta
    );
  },
  resetSocieta: (state) => {
    state.currentPageSocieta = 1;
    state.denominazioneSocieta = null;
    state.comitato = null;
    state.cod_affiliazione = null;
    state.disciplina = null;
    state.regione = null;
    state.provincia = null;
    state.comune = null;
    state.stagione = null;
    state.stato = null;
    state.affiliazioneDal = null;
    state.affiliazioneAl = null;
    state.documentiModificabili = null;
    state.sortColumnSocieta = "id";
    state.sortOrderSocieta = "desc";
    state.rowsToSkipSocieta = 0;
    state.comuneText = "";
  },
  setRowsToSkipSocieta: (state) => {
    state.currentPageSocieta = 1;
    state.rowsToSkipSocieta = 0;
  },
  setDocumentiModificabili: (state, value) => {
    state.documentiModificabili = value;
  },
  setStato: (state, value) => {
    state.stato = value;
  },
  setCodAffiliazione: (state, value) => {
    state.cod_affiliazione = value;
  },
  setDenominazione: (state, value) => {
    state.denominazioneSocieta = value;
  },
  setComitato: (state, value) => {
    state.comitato = value;
  },
  setDisciplina: (state, value) => {
    state.disciplina = value;
  },
  setInizioAff: (state, value) => {
    state.affiliazioneDal = value;
  },
  setFineAff: (state, value) => {
    state.affiliazioneAl = value;
  },
  setRegioneSoc: (state, value) => {
    state.regione = value;
  },
  setProvinciaSoc: (state, value) => {
    state.provincia = value;
  },
  setComuneSoc: (state, value) => {
    state.comune = value;
  },
  setSelectedComuneText: (state, value) => {
    state.comuneText = value;
  },
  setSortColumnSocieta: (state, value) => {
    state.sortColumnSocieta = value;
  },
  setSortOrderSocieta: (state, value) => {
    state.sortOrderSocieta = value;
  },
  setTutteStagioniSocieta: (state) => {
    state.tutteStagioniSocieta = !state.tutteStagioniSocieta;
    state.currentPageSocieta = 1;
    state.rowToSkipSocieta = 0;
  },
  resetFiltersSocieta: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
