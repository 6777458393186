const getDefaultState = () => {
  return {
    nomeEventiRichieste: "",
    rowsToSkipEventiRichieste: 0,
    fetchRowsEventiRichieste: 25,
    currentPageEventiRichieste: 1,
    sortColumnEventiRichieste: "data_ora_richiesta",
    sortOrderEventiRichieste: "DESC",
    tutteStagioniEventiRichieste: false,
    visualizzazionePerSocietaEventiRichieste: false, // cambiato il 24/02
    idSessioneEventiRichieste: null,
    daConfermareEventiRichieste: false,
    idSocietaEventiRichieste: null,
    textSocietaEventiRichieste: null,
    dataInizioEventiRichieste: null,
    dataFineEventiRichieste: null,
  };
};
const state = getDefaultState();

const getters = {
  nomeEventiRichieste: (state) => state.nomeEventiRichieste,
  rowsToSkipEventiRichieste: (state) => state.rowsToSkipEventiRichieste,
  fetchRowsEventiRichieste: (state) => state.fetchRowsEventiRichieste,
  currentPageEventiRichieste: (state) => state.currentPageEventiRichieste,
  sortColumnEventiRichieste: (state) => state.sortColumnEventiRichieste,
  sortOrderEventiRichieste: (state) => state.sortOrderEventiRichieste,
  tutteStagioniEventiRichieste: (state) => state.tutteStagioniEventiRichieste,
  visualizzazionePerSocietaEventiRichieste: (state) =>
    state.visualizzazionePerSocietaEventiRichieste,
  idSessioneEventiRichieste: (state) => state.idSessioneEventiRichieste,
  daConfermareEventiRichieste: (state) => state.daConfermareEventiRichieste,
  idSocietaEventiRichieste: (state) => state.idSocietaEventiRichieste,
  textSocietaEventiRichieste: (state) => state.textSocietaEventiRichieste,
  dataInizioEventiRichieste: (state) => state.dataInizioEventiRichieste,
  dataFineEventiRichieste: (state) => state.dataFineEventiRichieste,
};

// const actions = {};
const mutations = {
  assignStateValueEventiRichieste: (state, { value, stateName }) => {
    state[stateName] = value;
  },
  setFetchRowsEventiRichieste: (state, value) => {
    state.currentPageEventiRichieste = 1;
    state.rowsToSkipEventiRichieste = 0;
    state.fetchRowsEventiRichieste = value;
  },
  setCurrentPageEventiRichieste: (state, value) => {
    state.currentPageEventiRichieste = value;
    state.rowsToSkipEventiRichieste = parseInt(
      (state.currentPageEventiRichieste - 1) * state.fetchRowsEventiRichieste
    );
  },
  setSortColumnEventiRichieste: (state, value) => {
    state.sortColumnEventiRichieste = value;
  },
  setSortOrderEventiRichieste: (state, value) => {
    state.sortOrderEventiRichieste = value;
  },
  resetFiltersEventiRichieste: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipEventiRichieste: (state) => {
    state.currentPageEventiRichieste = 1;
    state.rowsToSkipEventiRichieste = 0;
  },
  setTutteLeStagioniEventiRichieste: (state) => {
    state.tutteStagioniEventiRichieste = !state.tutteStagioniEventiRichieste;
    state.currentPageEventiRichieste = 1;
    state.rowsToSkipEventiRichieste = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
